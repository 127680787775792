.wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 10px;
}

.container {
  
  --indent-left: 1;
  --indent-right: 1;
  --border-radius-tl: 0.5rem;
  --border-radius-tr: 0.5rem;
  --border-radius-br: 0.5rem;
  --border-radius-bl: 0.5rem;
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  max-width: 100%;
  position: relative;
  z-index: 1;  
  display: inline-block;
  width: 40rem;
  background-image: linear-gradient(45deg, rgba(0,0,0,0.4) 15%, rgba(0,0,0,0.502) 60%);
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: cover;
  border-radius: 1.5rem;
}

.page {
  --gutters: 2rem;
  --padding-horizontal: 1.5rem;
  --padding-vertical: 2.375rem;
  --spacing: 0.5rem;
  padding: var(--padding-vertical) var(--padding-horizontal);
}

