@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#BSocial li {
  display: inline-block;
}
#BSocial {
  padding-top: 10px;
}

.namebio {
  text-transform: none;
  color: #ffffff;
  font-size: 4.625vw;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 0px;
  word-spacing: 0px;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: capitalize;
  text-shadow: 0.133rem 0.133rem 0.125rem pink;
  margin-bottom: 1rem;
}

.textbio {
  color: #ffffff;
  letter-spacing: 0.025rem;
  width: calc(100% + 0.025rem);
  text-align: center;
  font-size: 1.125em;
  line-height: 1.75;
  font-weight: 400;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.Titulos {
  color: #fff;
  letter-spacing: 0.025rem;
  font-size: 1em;
  line-height: 1.75;
  font-weight: 400;
  text-shadow: 0.110rem 0.1rem 0.125rem #b5b5b5;
}

.Titulosab {
  color: #fff;
  letter-spacing: 0.025rem;
  font-size: 1.5em;
  line-height: 1.75;
  font-weight: 400;
  text-shadow: 0.07rem 0.09rem 0.125rem #b5b5b5;
}

.container-block {
  width: 320px;  
}

@keyframes xfade {
  25% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}
@keyframes fade {
  20% {
    visibility: visible;
  }
  30% {
    visibility: hidden;
  }
}
.image-holder {
  height: 320px;
}

.image-holder .image-content-slide {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 320px;
  transform: translate(-50%, -50%);
  animation: xfade 21s infinite;
  opacity: 0;
  max-height: 213px;
  box-sizing: border-box;
  border-radius: 1rem;
}

.image-holder i {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 40%;
  margin-top: auto;
  padding-right: 12px;
  left: 0;
  bottom: -30px;
  z-index: 1000;
  background: #f48b00;
  animation: fade 21s infinite;
  visibility: hidden;
  
}

.image-content-slide:nth-child(1) {
  animation-delay: 16s;
}

.image-content-slide:nth-child(2) {
  animation-delay: 14s;
}

.image-content-slide:nth-child(3) {
  animation-delay: 12s;
}

.image-content-slide:nth-child(4) {
  animation-delay: 10s;
}

.image-content-slide:nth-child(5) {
  animation-delay: 8s;
}

.image-content-slide:nth-child(6) {
  animation-delay: 6s;
}

.image-content-slide:nth-child(7) {
  animation-delay: 4s;
}

.image-content-slide:nth-child(8) {
  animation-delay: 2s;
}

.image-content-slide:nth-child(9) {
  animation-delay: 0s;
}


.image-holder {
  position: relative;
  text-align: left;
  font-size: 0;
  height: 213px;
}


